import React from "react";
import { Routes, Route } from "react-router-dom";
import { Cart, PDP, PLP } from "../pages";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<PLP />} />
      <Route path="/:id" element={<PDP />} />
      <Route path="/cart" element={<Cart />} />
    </Routes>
  );
};

export default Router;
