import React from "react";
import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const HeaderAvatar = ({
  username,
  onLogout,
}: {
  username: string;
  onLogout: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<i className="bi bi-chevron-down" />}
        variant="outline"
      >
        <Flex align="center">
          <Avatar
            size="sm"
            name={username}
            src={"https://bit.ly/dan-abramov"}
          />
          <Text m={0} ml={2} fontSize="sm" fontWeight="bold">
            {username}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem color={"#000"} onClick={onLogout}>
          {t("header.logout")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default HeaderAvatar;
