import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { Cart } from "../../types/Cart";

export interface CartState {
  cart: Cart[];
  cartId: number;
}

const initialState: CartState = {
  cart: [],
  cartId: -1,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCart: (state) => {
      state.cart = [];
    },
    updateCart: (state, action: PayloadAction<Cart[] | []>) => {
      state.cart = action.payload;
    },
    setCardId: (state, action: PayloadAction<number>) => {
      state.cartId = action.payload;
    },
  },
});

export const { resetCart, updateCart, setCardId } = cartSlice.actions;

export const getCurrentCart = (state: RootState) => state.cart.cart;
export const getCurrentCartId = (state: RootState) => state.cart.cartId;

export default cartSlice.reducer;
