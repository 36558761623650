import { useCallback } from "react";
import {
  getCurrentCart,
  getCurrentCartId,
  setCardId,
  updateCart,
} from "../reducer/cart/CartSlice";
import axios from "axios";
import { debounce } from "lodash";
import { useAppSelector, useAppDispatch } from "./store";
import { useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { getUserCartStatus, toggleCartStatus } from "../reducer/auth/AuthSlice";
import { BASE_URL, ENDPOINTS } from "../utils/url";

export interface CartItem {
  productId: number;
  quantity: number;
}

export interface UseCartOptions {
  debounceTime?: number;
}

const useCart = (options: UseCartOptions = {}) => {
  const { debounceTime = 500 } = options;
  const user = useAuth();
  const dispatch = useAppDispatch();
  const hasCart = useAppSelector(getUserCartStatus);
  const cartItems = useAppSelector(getCurrentCart);
  const cartId = useAppSelector(getCurrentCartId);

  const addMutation = useMutation({
    mutationFn: (items: CartItem[]) =>
      axios
        .post(`${BASE_URL}${ENDPOINTS.carts}`, {
          products: items,
          date: "2020-02-03",
          userId: user?.sub,
        })
        .then((res) => res.data),
    onSuccess: (data: { id: number }) => {
      dispatch(toggleCartStatus(true));
      dispatch(setCardId(data?.id));
    },
    onError: (error: { token: string }) => {
      console.error("Failed to update cart:", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (items: CartItem[]) =>
      axios
        .put(`${BASE_URL}${ENDPOINTS.carts}/${cartId}`, {
          products: items,
          date: "2020-02-03",
          userId: user?.sub,
        })
        .then((res) => res.data),
    onSuccess: (data: { token: string }) => {
      console.log("Cart updated successfully:", data);
    },
    onError: (error: { token: string }) => {
      console.error("Failed to update cart:", error);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCartDebounced = useCallback(
    debounce(
      (items: CartItem[]) => {
        if (hasCart) {
          updateMutation.mutate(items);
        } else {
          addMutation.mutate(items);
        }
      },
      debounceTime,
      {
        maxWait: 1000,
      }
    ),
    [hasCart]
  );

  const addToCart = useCallback(
    (productId: number, quantity: number) => {
      let updatedCart = [...cartItems];
      const existingIndex = updatedCart.findIndex(
        (item) => item.productId === productId
      );
      if (existingIndex !== -1) {
        if (quantity > 0) {
          updatedCart[existingIndex] = {
            ...updatedCart[existingIndex],
            quantity: quantity,
          };
        } else {
          updatedCart = updatedCart?.filter(
            (item) => item.productId !== productId
          );
          dispatch(updateCart(updatedCart));
          if (user) {
            updateCartDebounced(updatedCart);
          }
        }
      } else {
        updatedCart.push({ productId, quantity });
      }
      dispatch(updateCart(updatedCart));
      if (user) {
        updateCartDebounced(updatedCart);
      }
    },
    [dispatch, cartItems, updateCartDebounced, user]
  );

  const removeFromCart = useCallback(
    (productId: number) => {
      const updatedCart = cartItems.filter(
        (item) => item.productId !== productId
      );
      dispatch(updateCart(updatedCart));
      if (user) {
        updateCartDebounced(updatedCart);
      }
    },
    [dispatch, cartItems, updateCartDebounced, user]
  );

  return { cartItems: cartItems, addToCart, removeFromCart };
};

export default useCart;
