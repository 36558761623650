import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useQuery } from "@tanstack/react-query";
import {
  AspectRatio,
  Box,
  Button,
  Grid,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import useCart from "../hooks/cart";
import { BASE_URL, ENDPOINTS } from "../utils/url";

const PDP = () => {
  const { id } = useParams();
  const { cartItems, addToCart } = useCart();

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const toggleModal = () => {
    setOpen(!open);
  };

  const { isPending, error, data } = useQuery({
    queryKey: ["product", id],
    queryFn: () =>
      axios
        .get(`${BASE_URL}${ENDPOINTS.products}/${id}`)
        .then((res) => res.data),
    staleTime: 600000,
  });

  if (isPending)
    return (
      <Box>
        <Skeleton height="500px" />
      </Box>
    );

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${data?.name || ""} - Fake Product`}</title>
        <meta
          property="og:title"
          content="Check out this product by fake store"
        />
        <meta name="description" content={data?.description} />
      </Helmet>
      {data ? (
        <Box p={4}>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 2fr" }}
            gap={{ base: 4, md: 8 }}
            alignItems="center"
          >
            <Box cursor="zoom-in" onClick={toggleModal}>
              <AspectRatio ratio={4 / 6} maxW="300px" mx="auto">
                <LazyLoadImage
                  src={data?.image}
                  alt={data?.title}
                  height={"100%"}
                  width={"100%"}
                  effect="blur"
                />
              </AspectRatio>
            </Box>
            <Box>
              <VStack align="flex-start" spacing={2}>
                <Text fontSize="sm" fontWeight="bold">
                  {data?.title}
                </Text>
                <Text fontSize="xs">{data?.description}</Text>
                <Text fontSize="xs">{data?.category}</Text>
                <Text fontSize="xs">${data?.price}</Text>
                <Box display="flex" gap="1">
                  <i style={{ color: "yellow" }} className="bi bi-star-fill" />
                  <Text fontSize="sm">{data?.rating?.rate}</Text>
                  <Text fontSize="sm">({data?.rating?.count})</Text>
                </Box>
                {cartItems?.some((item) => item?.productId === data?.id) ? (
                  <Text>
                    <HStack spacing={2} alignItems="center">
                      <Button
                        onClick={() =>
                          addToCart(
                            data?.id,
                            (cartItems?.find(
                              (item) => item?.productId === data?.id
                            )?.quantity || 0) - 1
                          )
                        }
                        colorScheme="blue"
                        size="sm"
                        variant="outline"
                      >
                        -
                      </Button>
                      <Text fontWeight="bold" m={0}>
                        {
                          cartItems?.find(
                            (item) => item?.productId === data?.id
                          )?.quantity
                        }
                      </Text>
                      <Button
                        onClick={() =>
                          addToCart(
                            data?.id,
                            (cartItems?.find(
                              (item) => item?.productId === data?.id
                            )?.quantity || 0) + 1
                          )
                        }
                        colorScheme="blue"
                        size="sm"
                        variant="outline"
                      >
                        +
                      </Button>
                    </HStack>
                  </Text>
                ) : (
                  <Button
                    onClick={() => addToCart(data?.id, 1)}
                    colorScheme="blue"
                  >
                    {t("addToCart")}
                  </Button>
                )}
              </VStack>
            </Box>
          </Grid>
        </Box>
      ) : (
        <Box p={4}>
          <Text textAlign="center" fontSize="lg" fontWeight="bold">
            {t("notFound")}
          </Text>
        </Box>
      )}
      <Modal isOpen={open} onClose={toggleModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody cursor="zoom-out" onClick={toggleModal}>
            <Image src={data?.image} alt={data?.title} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PDP;
