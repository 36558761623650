import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Stack,
  Input,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "../hooks/store";
import { setUser, toggleCartStatus } from "../reducer/auth/AuthSlice";
import { useTranslation } from "react-i18next";
import { Cart } from "../types/Cart";
import { setCardId, updateCart } from "../reducer/cart/CartSlice";
import { isValidInput } from "../utils/validations";

const LoginModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const mutation = useMutation({
    mutationFn: () =>
      axios
        .post("https://fakestoreapi.com/auth/login", {
          username,
          password,
        })
        .then((res) => res.data),
    onError: (error: { response: { data: string } }) => {
      console.error("Login failed:", JSON.stringify(error));
      toast({
        title: error?.response?.data || t("somethingWrong"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
    onSuccess: async (data: { token: string }) => {
      const decodedPayload = JSON.parse(atob(data?.token?.split(".")[1]));
      if (decodedPayload) {
        dispatch(setUser(decodedPayload));
        const response = await axios.get(
          `https://fakestoreapi.com/carts/user/${decodedPayload?.sub}`
        );

        const userCart = response.data;

        const mergedProducts = userCart?.reduce(
          (merged: Cart[], currentCart: { products: Cart[] }) => {
            currentCart?.products?.forEach((product: Cart) => {
              const existingProductIndex = merged?.findIndex(
                (p) => p?.productId === product?.productId
              );
              if (existingProductIndex !== -1) {
                merged[existingProductIndex].quantity += product.quantity;
              } else {
                merged.push(product);
              }
            });
            return merged;
          },
          []
        );

        if (mergedProducts?.length) {
          dispatch(updateCart(mergedProducts));
          dispatch(toggleCartStatus(true));
          dispatch(setCardId(userCart?.[0]?.id));
        } else {
          dispatch(updateCart([]));
        }
      }
      toast({
        title: t("login.loginSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    },
  });

  const resetErrors = () => {
    setUsernameError("");
    setPasswordError("");
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    resetErrors();
    // Check for empty fields
    if (!username || !password) {
      if (!username) {
        setUsernameError(t("login.userNameRequired"));
      }
      if (!password) {
        setUsernameError(t("login.passwordRequired"));
      }
      return;
    }
    mutation.mutate();
  };

  return (
    <Modal
      onCloseComplete={resetErrors}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />
      <ModalContent m={5} p={5} borderRadius="xl">
        <ModalHeader textAlign="center" fontSize="2xl">
          {t("header.heading")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl isInvalid={!!usernameError}>
                <Input
                  type="username"
                  placeholder={t("login.username")}
                  value={username?.trim()}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      setUsername(e.target.value);
                    }
                  }}
                />
                <FormErrorMessage>{usernameError}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!passwordError}>
                <Input
                  type="password"
                  placeholder={t("login.password")}
                  value={password?.trim()}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      setPassword(e.target.value);
                    }
                  }}
                />
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
              <Button
                isLoading={mutation.isPending}
                colorScheme="blue"
                type="submit"
                borderRadius="full"
                _hover={{ bg: "blue.500" }}
              >
                {t("login.login")}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
