import * as React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Router from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Layout } from "./components";

export const App = () => {
  const queryClient = new QueryClient();

  const theme = extendTheme({
    fonts: {
      body: "'NunitoSans', sans-serif",
      heading: "'NunitoSans', sans-serif",
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Router />
        </Layout>
      </QueryClientProvider>
    </ChakraProvider>
  );
};
