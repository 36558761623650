import React from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { SimpleGrid } from "@chakra-ui/react";
import { ProductCard, ProductCardSkeleton } from "../components";
import {
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import { BASE_URL, ENDPOINTS } from "../utils/url";

const PLP = ({ scrollPosition }: { scrollPosition: ScrollPosition }) => {
  const { isPending, error, data } = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      axios.get(`${BASE_URL}${ENDPOINTS.products}`).then((res) => res.data),
    staleTime: 600000,
  });

  if (isPending) {
    const skeletonCount = 12;
    const skeletons = Array.from({ length: skeletonCount }).map((_, index) => (
      <ProductCardSkeleton key={index} />
    ));

    return (
      <SimpleGrid
        spacing={4}
        templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      >
        {skeletons}
      </SimpleGrid>
    );
  }

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Product Listing Page - Fake Store</title>
        <meta property="og:title" content="Check out fake store products" />
        <meta name="description" content="Browse our collection of products." />
      </Helmet>
      <SimpleGrid
        spacing={4}
        templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      >
        {data?.map((item: Product) => (
          <ProductCard
            scrollPosition={scrollPosition}
            item={item}
            key={item?.id}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

export default trackWindowScroll(PLP);
