import React, { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box, Flex } from "@chakra-ui/react";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Header />
        <Box flex="1">{children}</Box>
        <Footer />
      </Flex>
    </>
  );
};

export default Layout;
