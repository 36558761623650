import React from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage, ScrollPosition } from "react-lazy-load-image-component";

import {
  AspectRatio,
  Box,
  Card,
  CardBody,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

const ProductCard = ({
  item,
  scrollPosition,
}: {
  item: Product;
  scrollPosition: ScrollPosition;
}) => {
  const navigate = useNavigate();

  return (
    <Card maxW="sm" cursor={"pointer"} onClick={() => navigate(`/${item?.id}`)}>
      <CardBody>
        <AspectRatio maxW="150px" ratio={4 / 6} mx="auto">
          <LazyLoadImage
            style={{ position: "absolute", bottom: 0 }}
            alt={item?.title}
            effect="blur"
            src={item?.image}
            width="100%"
            height="100%"
            scrollPosition={scrollPosition}
          />
        </AspectRatio>

        <Stack mt="4" spacing="1">
          <Heading
            fontSize="xs"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item?.title || ""}
          </Heading>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Text color="blue.600" m={0} fontSize="xs">
              {`$${item?.price}`}
            </Text>
            <Box display="flex" gap="1">
              <i style={{ color: "yellow" }} className="bi bi-star-fill" />
              <Text m={0} fontSize={"xs"}>
                {item?.rating?.rate}
              </Text>
              <Text m={0} fontSize={"xs"}>
                ({item?.rating?.count})
              </Text>
            </Box>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ProductCard;
