import React from "react";
import { Box, Link, HStack, Center } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box bg="blue.500" mt={5} p={4} color="white">
      <Center>
        <HStack spacing={4}>
          <Link href="#" color="white">
            {t("footer.privacyPolicy")}
          </Link>
          <Link href="#" color="white">
            {t("footer.termsOfService")}
          </Link>
          <Link href="#" color="white">
            {t("footer.contactUs")}
          </Link>
        </HStack>
      </Center>
    </Box>
  );
};

export default Footer;
