import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, Heading, Spacer, HStack, Text } from "@chakra-ui/react";
import LoginModal from "./LoginModal";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks/store";
import HeaderAvatar from "./HeaderAvatar";
import { resetAuth } from "../reducer/auth/AuthSlice";
import { getCurrentCart } from "../reducer/cart/CartSlice";

const Header = () => {
  const user = useAuth();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cart = useAppSelector(getCurrentCart);

  const { t, i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    document.body.style.direction = language === "en" ? "ltr" : "rtl";
    i18n.changeLanguage(language);
  };

  const [open, setOpen] = useState(false);

  const handleNavigateToHome = () => {
    navigate("/");
  };

  const handleNavigateToCart = () => {
    navigate("/cart");
  };

  const handleLogout = () => {
    dispatch(resetAuth());
    handleNavigateToHome();
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <Box bg="blue.500" p={4} color="white" mb={5}>
      <Flex align="center">
        <Heading as="h1" size="md" mr={8} onClick={handleNavigateToHome}>
          {t("header.heading")}
        </Heading>
        <Spacer />
        <HStack spacing={4}>
          <Text
            cursor="pointer"
            fontWeight={location?.pathname === "/" ? "bold" : "light"}
            m={0}
            mr={4}
            onClick={handleNavigateToHome}
          >
            {t("header.home")}
          </Text>
          <Box
            position="relative"
            cursor="pointer"
            onClick={handleNavigateToCart}
          >
            <Box
              bg="red.500"
              color="white"
              borderRadius="full"
              position="absolute"
              top="-4px"
              right="-4px"
              width="20px"
              height="20px"
              display={cart?.length ? "flex" : "none"}
              justifyContent="center"
              alignItems="center"
              fontSize="sm"
              fontWeight="bold"
            >
              {cart?.length || 0}
            </Box>
            <Text
              cursor="pointer"
              fontWeight={location?.pathname === "/cart" ? "bold" : "light"}
              m={0}
              mr={4}
              onClick={handleNavigateToCart}
            >
              {t("header.cart")}
            </Text>
          </Box>
          <Text
            cursor="pointer"
            m={0}
            mr={4}
            onClick={() =>
              changeLanguage(i18n?.language === "en" ? "ar" : "en")
            }
          >
            {i18n?.language === "en" ? t("header.arabic") : t("header.english")}
          </Text>
          {user ? (
            <HeaderAvatar username={user?.user} onLogout={handleLogout} />
          ) : (
            <Text cursor="pointer" m={0} mr={4} onClick={toggleModal}>
              {t("header.login")}
            </Text>
          )}
        </HStack>
      </Flex>
      <LoginModal isOpen={open} onClose={toggleModal} />
    </Box>
  );
};

export default Header;
