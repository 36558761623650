import React, { useMemo, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  HStack,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { ConfirmationPopup } from "../components";
import useCart from "../hooks/cart";
import { BASE_URL, ENDPOINTS } from "../utils/url";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CartPage = () => {
  const toast = useToast();
  const { cartItems, addToCart, removeFromCart } = useCart();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      axios.get(`${BASE_URL}${ENDPOINTS.products}`).then((res) => res.data),
    staleTime: 600000,
  });

  const togglePopup = () => {
    setOpen(!open);
  };

  const removeItem = () => {
    removeFromCart(cartItems?.[selectedIndex]?.productId);
    toast({
      title: t("added"),
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    togglePopup();
  };

  const totalAmount = useMemo(() => {
    return cartItems?.reduce((total, item) => {
      const product = data?.find(
        (prod: Product) => prod?.id === item?.productId
      );
      const itemPrice = Number(product?.price) || 0;
      const itemQuantity = item?.quantity || 0;
      return total + itemPrice * itemQuantity;
    }, 0);
  }, [cartItems, data]);

  return (
    <>
      <TableContainer>
        {cartItems?.length ? (
          !isMobile ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>{t("cart.product")}</Th>
                  <Th>{t("cart.quantity")}</Th>
                  <Th isNumeric>{t("cart.price")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {cartItems?.map((item, index) => {
                  const product = data?.find(
                    (prod: Product) => prod?.id === item?.productId
                  );
                  return (
                    <Tr key={index}>
                      <Td>
                        <Flex>
                          <Box mx={4}>
                            <AspectRatio minWidth={100} ratio={4 / 5} mx="auto">
                              <LazyLoadImage
                                src={product?.image}
                                alt={product?.title}
                                height={"60%"}
                                width={"60%"}
                                effect="blur"
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                }}
                              />
                            </AspectRatio>
                          </Box>
                          <VStack align="flex-start" spacing={0}>
                            <Text fontSize="sm" fontWeight="bold">
                              {product?.title || ""}
                            </Text>
                            <Text fontSize="xs">
                              {`$${product?.price || ""}`}
                            </Text>
                            <Flex
                              mt="auto"
                              cursor="pointer"
                              onClick={() => {
                                setSelectedIndex(index);
                                togglePopup();
                              }}
                            >
                              <Text
                                fontSize="xs"
                                decoration={"underline"}
                                mr={2}
                              >
                                {t("remove")}
                              </Text>
                              <i className="bi bi-trash3-fill" />
                            </Flex>
                          </VStack>
                        </Flex>
                      </Td>
                      <Td>
                        <HStack spacing={2} alignItems={"center"}>
                          <Button
                            onClick={() =>
                              addToCart(item?.productId, item?.quantity - 1)
                            }
                            colorScheme="blue"
                            size="xs"
                            variant="outline"
                          >
                            -
                          </Button>
                          <Text fontSize="xs" fontWeight={"bold"} m={0}>
                            {item?.quantity || 0}
                          </Text>
                          <Button
                            onClick={() =>
                              addToCart(item?.productId, item?.quantity + 1)
                            }
                            colorScheme="blue"
                            size="xs"
                            variant="outline"
                          >
                            +
                          </Button>
                        </HStack>
                      </Td>
                      <Td isNumeric fontSize="xs">
                        {`$${(
                          (Number(product?.price) || 0) * (item?.quantity || 0)
                        )?.toFixed(2)}`}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th />
                  <Th>{t("cart.total")}</Th>
                  <Th isNumeric>${totalAmount.toFixed(2)}</Th>
                </Tr>
              </Tfoot>
            </Table>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              {cartItems?.map((item, index) => {
                const product = data?.find(
                  (prod: Product) => prod?.id === item?.productId
                );
                return (
                  <Box
                    key={index}
                    borderWidth="1px"
                    borderRadius="md"
                    overflow="hidden"
                    mx={4}
                  >
                    <AspectRatio ratio={4 / 4}>
                      <Box alignItems="center">
                        <LazyLoadImage
                          src={product?.image}
                          alt={product?.title}
                          height={"100%"}
                          width={"100%"}
                          effect="blur"
                          style={{ padding: 10 }}
                        />
                      </Box>
                    </AspectRatio>
                    <Flex direction="column" p={4}>
                      <Text fontSize="md" fontWeight="bold" mb={2}>
                        {product?.title || ""}
                      </Text>
                      <Text fontSize="sm" mb={2}>
                        {`$${product?.price || ""}`}
                      </Text>
                      <Flex justify="space-between" alignItems="center" mt={5}>
                        <Flex alignItems="center">
                          <Button
                            onClick={() =>
                              addToCart(item?.productId, item?.quantity - 1)
                            }
                            colorScheme="blue"
                            size="sm"
                            variant="outline"
                            mr={2}
                            disabled={item?.quantity === 1}
                          >
                            -
                          </Button>
                          <Text fontSize="md" fontWeight="bold" m={0} mr={2}>
                            {item?.quantity || 0}
                          </Text>
                          <Button
                            onClick={() =>
                              addToCart(item?.productId, item?.quantity + 1)
                            }
                            colorScheme="blue"
                            size="sm"
                            variant="outline"
                          >
                            +
                          </Button>
                        </Flex>
                        <Box
                          cursor="pointer"
                          display="flex"
                          onClick={() => {
                            setSelectedIndex(index);
                            togglePopup();
                          }}
                        >
                          <Text fontSize="md" textDecoration="underline" mr={2}>
                            {t("remove")}
                          </Text>
                          <i className="bi bi-trash3-fill" />
                        </Box>
                      </Flex>
                    </Flex>
                  </Box>
                );
              })}
            </SimpleGrid>
          )
        ) : (
          <Box p={isMobile ? 0 : 250} py={isMobile ? 250 : 0}>
            <Text textAlign="center" fontSize="lg" fontWeight="bold">
              {t("cart.empty")}
            </Text>
          </Box>
        )}
      </TableContainer>
      <ConfirmationPopup
        isOpen={open}
        onClose={togglePopup}
        onConfirm={removeItem}
        message={t("removeMessage")}
      />
    </>
  );
};

export default CartPage;
