import { Box, Card, CardBody, Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

const ProductCardSkeleton = () => {
  return (
    <Card maxW="sm">
      <CardBody>
        <Stack spacing="4">
          <Skeleton height="200px" />
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" />
          <Box display="flex" justifyContent="space-between">
            <Skeleton height="20px" width="100px" />
            <Skeleton height="20px" width="120px" />
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ProductCardSkeleton;
