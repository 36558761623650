import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { User } from "../../types/User";

export interface AuthState {
  user: User | null;
  hasCart: boolean;
}

const initialState: AuthState = {
  user: null,
  hasCart: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.user = null;
      state.hasCart = false;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    toggleCartStatus: (state, action: PayloadAction<boolean>) => {
      state.hasCart = action.payload;
    },
  },
});

export const { resetAuth, setUser, toggleCartStatus } = authSlice.actions;

export const getCurrentUser = (state: RootState) => state.auth.user;
export const getUserCartStatus = (state: RootState) => state.auth.hasCart;

export default authSlice.reducer;
